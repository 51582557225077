import Swal from 'sweetalert2';

export const editSettings = {
  allowEditing: true,
  allowAdding: true,
  allowDeleting: true,
  mode: 'Dialog',
  dialog: {
    height: '500px',
    width: '1000px',
    header: 'Add Crew Member',
    animationSettings: { effect: 'FadeZoom', duration: 400 },
  },
};

export const validationRules = {
  firstName: { required: true },
  lastName: { required: true },
  company: { required: true },
  role: { required: true },
  phone: { required: true },
  email: { required: true, email: true },
};

export const formInputs = [
  {
    field: 'firstName',
    headerText: 'First Name',
    width: '120',
    validationRules: validationRules.firstName,
  },
  {
    field: 'lastName',
    headerText: 'Last Name',
    width: '150',
    validationRules: validationRules.lastName,
  },
  {
    field: 'company',
    headerText: 'Company',
    width: '120',
    validationRules: validationRules.company,
  },
  {
    field: 'role',
    headerText: 'Job Title / Role',
    width: '120',
    validationRules: validationRules.role,
  },
  {
    field: 'phone',
    headerText: 'Phone Number',
    width: '120',
    validationRules: validationRules.phone,
  },
  {
    field: 'email',
    headerText: 'Email',
    width: '150',
    validationRules: validationRules.email,
  },
];

export const pageSettings = { pageCount: 5 };

const autoCloseAfter = 10000; // auto close popup time
let timerInterval;
export const alertConfig = {
  title: 'Training Orientation completed!',
  html: `Crew list accepted.  You'll receive an email shortly to confirm your submission.`,
  footer: 'Popup will automatically close in <b></b>',
  icon: 'success',
  timer: autoCloseAfter,
  timerProgressBar: true,
  showConfirmButton: false,
  didOpen: () => {
    // get reference to popup text to dynamically update
    const b = Swal.getFooter().querySelector('b');
    // set initial counter on popup
    b.textContent = Math.floor((autoCloseAfter / 1000) % 60) + 1;
    timerInterval = setInterval(() => {
      // update counter on popup
      b.textContent = Math.floor((Swal.getTimerLeft() / 1000) % 60) + 1;
    }, 100);
  },
  willClose: () => {
    // clear interval when popup closes
    clearInterval(timerInterval);
  },
  customClass: {
    timerProgressBar: 'pseColoring',
  },
};

import LogoutIcon from '@mui/icons-material/Logout';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import logo from '../assets/img/logos/PSE_Stacked_REV.png';
import { useAuth } from '../context/authContext';

function NavBar() {
  const auth = useAuth();

  const handleSignOut = (e) => {
    e.preventDefault();
    auth.logout();
  };

  return (
    <AppBar position='static'>
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              variant='h6'
              noWrap
              component='a'
              href='/'
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <Box
                component='img'
                sx={{ width: '125px', p: 2 }}
                src={logo}
                alt='PSE Logo'
              />
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title='Sign Out'>
              <Button
                sx={{ color: '#fff' }}
                variant='outlined'
                endIcon={<LogoutIcon />}
                onClick={handleSignOut}
              >
                Sign Out
              </Button>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;

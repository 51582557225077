import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import LoginIcon from '@mui/icons-material/Login';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import bgImage from '../assets/img/backgrounds/bainbridge-island-1-13-2020_49387715542_o.jpg';
import logo from '../assets/img/logos/PSE_Horizontal_RGB.png';
import { useAuth } from '../context/authContext';
import useLogin from '../hooks/useLogin';

const validationSchema = Yup.object().shape( {
  registrationId: Yup.string().required( 'Registration ID is required' ),
  pin: Yup.number().required( 'Pin is required' ),
} );

const Login = () => {
  const { login, user, loading, error } = useLogin();
  const auth = useAuth();
  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();

  useEffect( () => {
    if ( auth.isAuthenticated ) navigate( '/landing', { replace: true } );
  }, [ auth.isAuthenticated, navigate ] );

  const formik = useFormik( {
    initialValues: {
      registrationId: searchParams.get( 'id' ) || '',
      pin: searchParams.get( 'pin' ) || '',
    },
    validationSchema: validationSchema,
    onSubmit: async ( values ) => {
      try
      {
        const res = await login( values );
        if ( res.message === 'Login successful' )
        {
          auth.login( res.user );
        }
      } catch ( e )
      {
        console.error( 'error', [ e ] );
      }
    },
  } );

  useEffect( () => {
    if ( searchParams.get( 'id' ) && searchParams.get( 'pin' ) && !formik.isSubmitting && !loading && !error )
    {
      formik.handleSubmit();
    }
  }, [ formik, searchParams, error, loading ] );

  return (
    <Container
      component='main'
      maxWidth={ false }
      disableGutters
      sx={ {
        display: 'flex',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: `url(${ bgImage })`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      } }
    >
      <Paper
        elevation={ 6 }
        square={ false }
        sx={ {
          p: 2,
          width: 400,
        } }
      >
        <Box component='img' sx={ { width: '100%' } } src={ logo } alt='PSE Logo' />
        <Typography component='h1' variant='h3' align='center' sx={ { my: 1 } }>
          Foreign Crew Orientation
        </Typography>
        <Stack
          direction='column'
          justifyContent='space-between'
          alignItems='stretch'
          spacing={ 3 }
          sx={ {
            display: 'flex',
            flexFlow: 'column wrap',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            my: 3,
          } }
          component='form'
          onSubmit={ formik.handleSubmit }
        >
          <TextField
            fullWidth
            id='registrationId'
            name='registrationId'
            label='Registration ID'
            value={ formik.values.registrationId }
            onChange={ formik.handleChange }
            error={
              formik.touched.registrationId &&
              Boolean( formik.errors.registrationId )
            }
            helperText={
              formik.touched.registrationId && formik.errors.registrationId
            }
          />
          <TextField
            fullWidth
            id='pin'
            name='pin'
            label='Pin'
            value={ formik.values.pin }
            onChange={ formik.handleChange }
            error={ formik.touched.pin && Boolean( formik.errors.pin ) }
            helperText={ formik.touched.pin && formik.errors.pin }
          />
          <LoadingButton
            fullWidth
            size='large'
            type='submit'
            endIcon={ <LoginIcon /> }
            loading={ loading }
            loadingPosition='end'
            variant='contained'
          >
            <span>Sign In</span>
          </LoadingButton>
        </Stack>
        { error && <Alert severity='error'>{ error }</Alert> }
        { !loading && user && <Alert severity='success'>Login Successful</Alert> }
      </Paper>
      <Box sx={ { position: 'absolute', bottom: 0, right: 2 } }>
        <Typography
          variant='caption'
          display='block'
          sx={ { textTransform: 'lowercase' } }
        >
          {`v${process.env.REACT_APP_VERSION}${process.env.NODE_ENV.charAt(0)}`}
        </Typography>
      </Box>
    </Container>
  );
};

export default Login;

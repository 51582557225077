import { Outlet } from 'react-router-dom';

import Container from '@mui/material/Container';

import NavBar from '../components/NavBar';

function ResponsiveAppBar() {

  return (
    <Container disableGutters maxWidth={false}>
      <NavBar />
      <Container sx={{ mt: 2 }} disableGutters component='main'>
        <Outlet />
      </Container>
    </Container>
  );
}
export default ResponsiveAppBar;

import { Link } from 'react-router-dom';

import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded';
import QuizRoundedIcon from '@mui/icons-material/QuizRounded';
import RecentActorsRoundedIcon from '@mui/icons-material/RecentActorsRounded';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const tiers = [
  {
    order: 1,
    title: 'Safety Video',
    // subtitle: 'Foreign Crew Safety Orientation Video',
    description: `Rules and guidelines to use while out in the field`,
    icon: OndemandVideoRoundedIcon,
  },
  {
    order: 2,
    title: 'Quiz',
    description:
      'Ten (10) question quiz about information in the orientation video',
    icon: QuizRoundedIcon,
  },
  {
    order: 3,
    title: 'Crew Submission',
    description:
      'Submit crew member(s) whom completed Orientation Training this session',
    icon: RecentActorsRoundedIcon,
  },
];

export default function Landing() {
  return (
    <Grid container spacing={4}>
      <Grid
        item
        justifyContent='center'
        alignItems='center'
        xs={12}
        mb={2}
      >
        <Typography
          component='h1'
          variant='h1'
          align='center'
          color='text.primary'
          pt={2}
          gutterBottom
        >
          PSE Foreign Crew Safety Orientation
        </Typography>
        <Typography variant='body2' align='center' color='text.secondary'>
          Orientation consists of three (3) parts. Each step must be completed
          in order to complete orientation.
        </Typography>
      </Grid>
      <Grid
        container
        item
        direction='row'
        justifyContent='space-between'
        alignItems='stretch'
        xs={12}
        mx={2}
        // spacing={2}
      >
        {tiers.map((tier) => (
          <Grid
            item
            component={Card}
            key={tier.title}
            xs={12}
            md={3}
            mb={2}
            // pr={2}
            // pb={2}
            sx={{ boxShadow: 4 }}
          >
            <CardMedia
              component={tier.icon}
              sx={{
                width: '100%',
                textAlign: 'center',
                backgroundColor: (theme) => theme.palette.primary.main,
                color: '#fff',
                fontSize: 175,
                py: 3,
              }}
              alt='Paella dish'
            />
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              pt={2}
            >
              <Avatar
                alt={`Step ${tier.order}`}
                sx={{
                  bgcolor: (theme) => theme.palette.primary.main,
                  mr: 2,
                }}
              >
                {tier.order}
              </Avatar>
              {tier.title}
            </Box>
            <CardContent>
              {tier.subtitle && (
                <Typography variant='h5' align='center'>
                  {tier.subtitle}
                </Typography>
              )}
              {tier.subtitle && <br />}
              <Typography whiteSpace='pre-wrap' variant='h6' align='center'>
                {tier.description}
              </Typography>
            </CardContent>
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        item
        direction='column'
        justifyContent='center'
        alignItems='center'
        xs={12}
        spacing={4}
        mb={2}
      >
        <Grid
          item
          component={Typography}
          variant='subtitle2'
          align='center'
          color='text.secondary'
        >
          The training orientation needs to be completed in a single session.
          <br />
          Please ensure you have 15 minutes to complete the full orientation.
        </Grid>
        <Grid item>
          <Button
            component={Link}
            variant='contained'
            to='/training-orientation'
          >
            Click to Begin
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

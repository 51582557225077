import { useRef } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  GridComponent,
  Inject,
  Page,
  Selection,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import Swal from 'sweetalert2';

import { useAuth } from '../../context/authContext';
import { postCrewItems } from '../../hooks/useForeignCrewDb';
import { alertConfig, editSettings, formInputs } from './gridSettings';

import './styles.css';

function WatchList() {
  const auth = useAuth();
  const dataRef = useRef([
    {
      firstName: auth.user.firstName?.S || '',
      lastName: auth.user.lastName?.S || '',
      company: auth.user.company?.S || '',
      role: auth.user.role?.S || '',
      phone: auth.user.phone?.S || '',
      email: auth.user.email?.S || '',
    },
  ]);

  console.debug('authenticated', auth);

  const toolbar = [
    'Add',
    'Edit',
    'Delete',
    {
      text: 'Submit List',
      id: 'submit',
      align: 'right',
      suffixIcon: 'e-check',
      tooltipText: 'Submit the list of crew members',
      type: 'Button',
      click: async () => {
        postCrewItems(dataRef.current, auth.user.registrationId.S).then(() => {
          Swal.fire(alertConfig);
        });
      },
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} p={2}>
        <Typography variant='h1' textAlign='center'>
          Submit Crew Members
        </Typography>
      </Grid>
      <Grid item xs={12} p={2} textAlign='center'>
        <Typography variant='body2'>
          Add members of the crew that was present during this training
          orientation session.
          <br />
          Once the list is complete, click submit the list to complete the
          orientation.
        </Typography>
      </Grid>
      <Grid item container xs={12} className='control-pane' p={2}>
        <Grid item xs={12} className='control-section'>
          <Grid
            id='sfGridComponent'
            component={GridComponent}
            dataSource={dataRef.current}
            toolbar={toolbar}
            editSettings={editSettings}
            allowSelection={true}
            selectionSettings={{ persistSelection: true }}
            ref={(g) => {
              // save reference to table data
              if (g && g.dataSource) dataRef.current = g.dataSource;
            }}
          >
            <ColumnsDirective>
              {formInputs.map((input, index) => (
                <ColumnDirective
                  field={input.field}
                  headerText={input.headerText}
                  width={input.width}
                  validationRules={input.validationRules}
                  key={index}
                />
              ))}
            </ColumnsDirective>
            <Inject services={[Page, Toolbar, Selection, Edit]} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default WatchList;

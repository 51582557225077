import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { registerLicense } from '@syncfusion/ej2-base';

import ProtectedRoutes from './components/ProtectedRoute';
import { AuthProvider } from './context/authContext';
import { theme } from './context/themeContext';
import Landing from './pages/Landing';
import Login from './pages/Login';
import TrainingOrientation from './pages/TrainingOrientation';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@syncfusion/ej2-icons/styles/material.css';
import './App.css';

registerLicense(
  // eslint-disable-next-line no-undef
  process.env.REACT_APP_SYNCFUSION_LICENSE
);
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route element={<ProtectedRoutes />}>
              <Route
                path='/training-orientation'
                element={<TrainingOrientation />}
              />
              <Route path='/Landing' element={<Landing />} />
              <Route path='*' element={<Landing />} />
            </Route>
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;

import { useState } from 'react';

import axios from 'axios';

const useLogin = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-undef
  const apiEndpoint = new URL('/auth', process.env.REACT_APP_API);

  console.debug('apiEndpoint', apiEndpoint);

  const login = async (credentials) => {
    setLoading(true);
    setError();
    try {
      const res = await axios.post(apiEndpoint.href, credentials);
      setLoading(false);
      return res.data;
    } catch (err) {
      setLoading(false);
      setError(err.response.data.error);
    }
  };

  return { login, loading, error };
};

export default useLogin;

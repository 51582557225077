import YouTube from 'react-youtube';

import InfoIcon from '@mui/icons-material/Info';
import { Box, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import './style.css';

function YoutubeEmbed({ embedId, callback }) {
  const opts = {
    height: '585',
    width: '960',
    playerVars: {
      autoplay: 0,
    },
  };

  // when video ends
  const onPlayerStateChange = (event) => {
    if (event.data === 0) {
      callback();
    }
  };

  return (
    <Stack
      container
      spacing={2}
      justifyContent='center'
      alignItems='center'
      sx={{ pb: 2 }}
    >
      <Typography variant='h1'>Safety Orientation Video</Typography>
      <Grid
        item
        xs={12}
        component={YouTube}
        className='video-responsive'
        videoId={embedId}
        opts={opts}
        onEnd={onPlayerStateChange}
        sx={{ width: '90%' }}
      />
      <Box
        display='flex'
        mt={2}
        sx={{ bgcolor: '#ED9F1B', p: 1, borderRadius: 2 }}
      >
        <InfoIcon sx={{ mr: 1 }} />
        <Typography variant='body2' sx={{ lineHeight: 2 }}>
          You will be moved automatically to the quiz once the orientation video
          has ended
        </Typography>
      </Box>
    </Stack>
  );
}

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
